// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

// Commented on June 1 2023 because it caused conflicts with the toggle thing
// import "bootstrap/dist/js/bootstrap";

import "bootstrap-icons/font/bootstrap-icons.css";
import "@fortawesome/fontawesome-free/js/all";

import "../application/dashkit/tom_select";
import "../application/dashkit/filepond";
// import "../application/dashkit/autocomplete";
import "../application/dashkit/awesomplete";
import "../application/dashkit/tooltip";
import "../application/dashkit/burger";

// Theme
import "../application/dashkit/theme/theme";


Rails.start()
Turbolinks.start()
ActiveStorage.start()
