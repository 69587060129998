const Awesomplete = require("awesomplete");

document.addEventListener("turbolinks:load", () => {
  const inputs = document.querySelectorAll(".awesomplete-input");

  inputs.forEach((input) => {
    const awesompleteInstance = new Awesomplete(input, {
      filter: function (text, input) {
        return true;
      },
      item: function (suggestion, input) {
        const html = document.createElement("li");
        const item = document.createElement("span");
        const counter = document.createElement("span");

        item.textContent = suggestion.label.display_name;
        counter.textContent = suggestion.label.taggings_count
        html.classList.add(suggestion.label.color_class)
        counter.classList.add(suggestion.label.color_class)
        counter.classList.add('small')
        counter.classList.add('ps-2')

        html.appendChild(item);
        html.appendChild(counter);
        return html;
      },
      minChars: 3, // Add this line
    });

    // DROPDOWN PORTAL BEHAVIOR

    if (input.classList.contains('awesomplete-portalable')) {
      const dropdownPortal = document.createElement('div');
      dropdownPortal.classList.add('awesomplete-portal');
      document.body.appendChild(dropdownPortal);

      input.addEventListener('awesomplete-open', () => {
        if (!isResponsiveMode()) {
          updateDropdownPosition();
          dropdownPortal.appendChild(awesompleteInstance.ul);
        } else {
          input.parentElement.appendChild(awesompleteInstance.ul);
        }
      });

      input.addEventListener('awesomplete-close', () => {
        if (!isResponsiveMode()) {
          updateDropdownPosition();
          input.parentElement.appendChild(awesompleteInstance.ul);
        } else {
          input.parentElement.appendChild(awesompleteInstance.ul);
        }
      });

      function updateDropdownPosition() {
        if (awesompleteInstance.opened) {
          const inputRect = input.getBoundingClientRect();
          dropdownPortal.style.top = `${inputRect.bottom}px`;
          dropdownPortal.style.left = `${inputRect.left}px`;
          dropdownPortal.style.minWidth = `${inputRect.width}px`;
        }
      }

      const fixedNavbar = document.querySelector('.navbar');

      if (fixedNavbar) {
        fixedNavbar.addEventListener('scroll', updateDropdownPosition);
      } else {
        document.addEventListener('scroll', updateDropdownPosition);
      }

      window.addEventListener('resize', () => {
        if (awesompleteInstance.opened) {
          updateDropdownPosition();
          if (isResponsiveMode()) {
            input.parentElement.appendChild(awesompleteInstance.ul);
          } else {
            dropdownPortal.appendChild(awesompleteInstance.ul);
          }
        }
      });
    }

    function isResponsiveMode() {
      return window.innerWidth <= 768;
    }

    // AUTOCOMPLETION BEHAVIOR

    let debounceTimeout = null;

    input.addEventListener("input", function (event) {
      clearTimeout(debounceTimeout); // Clear the previous timeout if there is one

      debounceTimeout = setTimeout(() => {
        const inputValue = event.target.value;
        if (!inputValue) return;
        let lastWord = getLastWord(inputValue);

        // Strip the minus sign from the last word before fetching suggestions
        if (lastWord.startsWith("-")) {
          lastWord = lastWord.slice(1);
        }

        if (lastWord.length < 3) {
          // If the last word has less than three characters, don't fetch suggestions
          awesompleteInstance.list = [];
          return;
        }

        fetch(`/tags.json?autocomplete=${lastWord}`)
          .then((response) => response.json())
          .then((tags) => {
            awesompleteInstance.list = tags.map((tag) => ({
              label: tag,
              value: tag.name,
            }));
          });
        }, 125);
    });

    input.addEventListener("awesomplete-select", function (event) {
      event.preventDefault();
      const newTag = event.text.value;
      const words = input.value.split(/\s+/);
      const lastWord = getLastWord(input.value);
      const withoutLastWord = words.slice(0, -1).join(" ");

      // Check if the last word started with a minus sign
      const hasMinusSign = lastWord.startsWith("-");

      // Prepend the minus sign back to the selected tag if necessary
      const finalTag = hasMinusSign ? "-" + newTag : newTag;

      input.value = withoutLastWord + (withoutLastWord ? " " : "") + finalTag + " ";
      awesompleteInstance.close();
    });


    function getLastWord(inputValue) {
      const words = inputValue.split(/\s+/);
      return words[words.length - 1];
    }
  });
});
