window.TomSelect = require('tom-select');

document.addEventListener("turbolinks:load", () => {
  if (document.getElementsByClassName("tomselect").length > 0) {
    document.querySelectorAll(".tomselect").forEach((el) => {
      fetch(el.getAttribute("source")).then((response) => response.json()).then((data) => {
        const multiSelectList = document.getElementById(el.getAttribute("list"));
        const valueField = el.getAttribute("value_field") || 'name';

        const multiSelect = new TomSelect(el, {
        	options: data,
        	maxItems: null,
        	maxOptions: 10,
        	valueField: 'name',
        	labelField: "name",
        	searchField: "name",
        	create: false,
          onChange(args) {
            multiSelect.close();
            multiSelectList.innerHTML = "";
            if (args.length == 0) return;

            var tags = ( Array.isArray(args) ? args : args.split('+'));

            displayTags(multiSelect, multiSelectList, tags);
          },
        	render: {
            option(data, escape) {
              return `<div>${data.name}</div>`;
            },
          },
        });

        displayTags(multiSelect, multiSelectList, multiSelect.items);
      });
    });

    function displayTags(multiSelect, multiSelectList, values) {
      for (const value of values) {
        const cross = document.createElement("i");
        cross.onclick = function () { multiSelect.removeItem(`${value}`); };
        cross.classList.add('fe', 'fe-x', 'text-primary')
        const option = document.createElement("div");
        option.classList.add('badge', 'bg-primary-soft');
        option.innerHTML = multiSelect.options[value].name;
        option.append(cross);
        multiSelectList.append(option);
      }
    }
  }
});
