document.addEventListener("turbolinks:load", () => {
  var navbarToggler = document.getElementById('navbar-toggler');
  var navbarCollapse = document.getElementById('navbarBody');

  if (navbarToggler) {
    navbarToggler.addEventListener('click', function() {
      navbarCollapse.classList.toggle('collapse');
      navbarCollapse.classList.toggle('show');
    });
  }
});
