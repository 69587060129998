window.FilePond = require('filepond');
window.FilePondPluginImagePreview = require('filepond-plugin-image-preview');
window.FilePondPluginFileValidateSize = require('filepond-plugin-file-validate-size');
window.FilePondPluginFileValidateType = require('filepond-plugin-file-validate-type');

document.addEventListener("turbolinks:load", () => {

  FilePond.registerPlugin(
    FilePondPluginFileValidateSize,
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
  )

  document.querySelectorAll('.uploader').forEach(function(el) {

    var previewObject = el.getAttribute('preview');
    var hideInfo = el.getAttribute('hideInfo');
    var fileObject = el.getAttribute('file');
    var fileName = el.getAttribute('fileName');

    const inputFile = FilePond.create(el, {
      acceptedFileTypes: (el.getAttribute('accept') != null) ? el.getAttribute('accept') : 'image/*',
      labelIdle: (el.getAttribute('label') != null) ? el.getAttribute('label') : 'Drag & Drop your image or <span class="filepond--label-action">Browse</span>',
      maxFileSize: (el.getAttribute('maxSize') != null) ? el.getAttribute('maxSize') : '500MB',
      imagePreviewMaxHeight: (el.getAttribute('previewHeight') != null) ? el.getAttribute('previewHeight') : 378,
      storeAsFile: true,
      credits: false,
      allowReplace: (el.getAttribute('editable') != null) ? el.getAttribute('editable') : true,
      allowRevert: (el.getAttribute('editable') != null) ? el.getAttribute('editable') : true,
      allowRemove: (el.getAttribute('editable') != null) ? el.getAttribute('editable') : true
    })

    if (previewObject) {
      inputFile.addFile(previewObject).then((file) => {
        if (hideInfo == "true") {
          document.querySelectorAll('.filepond--file-info').forEach(function(fileInfoElement) {
            fileInfoElement.classList.add('d-none');
          });
        };
      })
    }

  });
});
